import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "filterDetailsContainer",
  class: "vflex"
}
const _hoisted_2 = { id: "feedbackStatusListDiv" }
const _hoisted_3 = ["id", "value"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "hflex" }
const _hoisted_6 = { class: "hflex" }
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "action-container hflex hflex-right" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedbackTags = _resolveComponent("FeedbackTags")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[16] || (_cache[16] = _createElementVNode("span", { class: "filter-cat-title" }, "Status", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusDetailedList, (statusDetail) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'status_'+statusDetail+'_div'
        }, [
          _createElementVNode("input", {
            id: 'feedbackFilterStatus'+statusDetail.value,
            type: "checkbox",
            value: statusDetail.value,
            class: "feedback-status-checkbox",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPendingChanges()))
          }, null, 40, _hoisted_3),
          _createElementVNode("label", {
            for: statusDetail.value
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.getStatusClass(statusDetail.value))
            }, null, 2),
            _createTextVNode(_toDisplayString(' - ' + statusDetail.label), 1)
          ], 8, _hoisted_4)
        ]))
      }), 128))
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("span", { class: "filter-cat-title" }, "Tags", -1)),
    _createVNode(_component_FeedbackTags, {
      ref: "tagsComponent",
      "input-placeholder": 'Add a tag here',
      onTagAdded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPendingChanges())),
      onTagsDeleted: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPendingChanges()))
    }, null, 512),
    _cache[18] || (_cache[18] = _createElementVNode("span", { class: "filter-cat-title" }, "Date", -1)),
    _createElementVNode("div", _hoisted_5, [
      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "date-picker-label" }, "from:", -1)),
      _createVNode(_component_VueDatePicker, {
        id: _ctx.dateFromInputId,
        modelValue: _ctx.dateFrom,
        "onUpdate:modelValue": [
          _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateFrom) = $event)),
          _cache[4] || (_cache[4] = ($event: any) => (_ctx.setPendingChanges()))
        ],
        placeholder: "Select a date (optional)",
        title: (_ctx.hasDateError) ? 'Date “from” cannot be prior to date “to”' : null,
        "auto-apply": true,
        locale: _ctx.getLocale,
        format: "yyyy/MM/dd",
        "enable-time-picker": false,
        class: _normalizeClass({'input-error': _ctx.hasDateError, 'flex-grow': true}),
        onOpen: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hasDateError = false))
      }, null, 8, ["id", "modelValue", "title", "locale", "class"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[13] || (_cache[13] = _createElementVNode("span", { class: "date-picker-label" }, "to:", -1)),
      _createVNode(_component_VueDatePicker, {
        id: _ctx.dateToInputId,
        modelValue: _ctx.dateTo,
        "onUpdate:modelValue": [
          _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dateTo) = $event)),
          _cache[7] || (_cache[7] = ($event: any) => (_ctx.setPendingChanges()))
        ],
        title: (_ctx.hasDateError) ? 'Date “from” cannot be prior to date “to”' : null,
        placeholder: "Select a date (optional)",
        "auto-apply": true,
        locale: _ctx.getLocale,
        format: "yyyy/MM/dd",
        "enable-time-picker": false,
        style: {"margin-bottom":"0px"},
        class: _normalizeClass({'input-error': _ctx.hasDateError, 'flex-grow': true}),
        onOpen: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hasDateError = false))
      }, null, 8, ["id", "modelValue", "title", "locale", "class"])
    ]),
    _cache[19] || (_cache[19] = _createElementVNode("span", { class: "filter-cat-title" }, "Author", -1)),
    _createElementVNode("input", {
      id: _ctx.authorInputId,
      type: "text",
      class: "styled-input-text styled-input-text-fullW filter-input",
      onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setPendingChanges()))
    }, null, 40, _hoisted_7),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        class: "btn red-styled-button",
        disabled: !_ctx.hasContent,
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.resetFilter()))
      }, _cache[14] || (_cache[14] = [
        _createTextVNode(" Reset "),
        _createElementVNode("i", { class: "fas fa-trash" }, null, -1)
      ]), 8, _hoisted_9),
      _createElementVNode("button", {
        class: "btn blue-styled-button",
        disabled: !_ctx.hasContent,
        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.applyFilter()))
      }, _cache[15] || (_cache[15] = [
        _createTextVNode(" Apply filter "),
        _createElementVNode("i", { class: "fas fa-check-circle" }, null, -1)
      ]), 8, _hoisted_10)
    ])
  ]))
}