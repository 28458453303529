import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({'btn feedback-small feedback-vote-btn': true, 'blue-styled-button': _ctx.isVotedUp, 'blue-styled-button-outline': !_ctx.isVotedUp}),
    disabled: !_ctx.isVotingPossible || _ctx.isVotedUp,
    title: (!_ctx.isVotingPossible) ? 'Liking is not possible with your browser' : ((_ctx.isVotedUp) ? 'You have already liked it' : 'Like it'),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doVoteUp()))
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("i", { class: "far fa-heart" }, null, -1)),
    _createTextVNode(" " + _toDisplayString(_ctx.voteValue), 1)
  ], 10, _hoisted_1))
}