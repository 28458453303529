/***
 * Feedback-related types
 */

export enum FeedbackStatus {
    New = 0,
    InProgress,
    Solved,
    Discarded,
    DoLater
}

export interface FeedbackStatusDetail {
    value: FeedbackStatus
    label: string
}

export interface FeedbackReply {
    id: number
    content: string
    datetime: Date
    author: string
    votes: number
}

export interface FeedbackItem {
    id: number
    title: string
    content: string
    status: FeedbackStatus
    "num_comments": number
    comments: FeedbackReply[]
    votes: number
    tags: string[]
    author: string
    datetime: Date
}

// Description in line with the DB object returned when querying for feedbacks
export interface DBFeedbackQueryResponse {
    feedback: FeedbackItem[],
    "total_entries" : {number: number}[] // the array will be of size 1
}

export interface FeedbackFilterData {
    search?: string,
    status?: number[],
    tags?: string[],
    author?: string,
    dateTimeFrom?: string,
    dateTimeTo?: string,
}
/** End feedback-related types */

/**
 * App constants-related types
 */
export interface DBConsts {
    serverhost: string
    extraShortTextMax: number
    shortTextMax: number
    longTextMax: number
    itemsPerLoad: number
}

export enum DBOperationType {
    None,
    Feedbacks,
    MoreFeedbacks,
    FeedbackComments,
    NewFeedback,
    NewFeedbackComment,
    AddVote,
    FilteredFeedbacks,
    MoreFilteredFeedbacks,
}

export interface DBOperation {
    type: DBOperationType,
    payload?: any[], // if any parameters are needed by the consumer to process the operation
}

export interface DBError {
    flag: boolean // to indicate if the DB has returned error
    error?: string // an optional error message
    operation: DBOperationType // the reason of the DB error
    payload?: any[] // if any parameters are needed by the consumer to process the error
}

/**
 * Strype history related types
 */
export interface StrypeHistoryEntry {
    date: string // the date of the release
    preambleDetails?: string // message that can be shown as a preamble
    generalDetails?: string[] // points for the general category
    standardEditorDetails?: string[] // points specific to the standard editor category
    microbitEditorDetails?: string[] // points specific to the micro:bit editor category
}
