import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card strype-card" }
const _hoisted_2 = { class: "card-body feedback-small" }
const _hoisted_3 = { class: "feedback-header feedback-medium" }
const _hoisted_4 = { class: "card-text feedback-text" }
const _hoisted_5 = {
  key: 0,
  class: "vflex replies-container"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "hflex hflex-centered" }
const _hoisted_8 = { class: "hflex flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Progress = _resolveComponent("Progress")!
  const _component_Error = _resolveComponent("Error")!
  const _component_FeedbackReply = _resolveComponent("FeedbackReply")!
  const _component_FeedbackNewReply = _resolveComponent("FeedbackNewReply")!
  const _component_FeedbackVote = _resolveComponent("FeedbackVote")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.feedbackItem.title), 1),
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.getStatusClass(_ctx.feedbackItem.status))
        }, null, 2)
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.feedbackItem.content), 1),
      (_ctx.isFeedbackRepliesOn(_ctx.feedbackItem.id))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.isDBLoadingFeedbackCommments)
              ? (_openBlock(), _createBlock(_component_Progress, {
                  key: 0,
                  label: "Loading...",
                  class: "feedbackComments-dbprocess-indicator"
                }))
              : (_ctx.hasDBError)
                ? (_openBlock(), _createBlock(_component_Error, {
                    key: 1,
                    label: _ctx.dbErrorMsg,
                    class: "feedbackComments-dbprocess-indicator"
                  }, null, 8, ["label"]))
                : (_ctx.canShowReplies)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedbackItem.comments, (reply) => {
                        return (_openBlock(), _createBlock(_component_FeedbackReply, {
                          key: _ctx.feedbackItem.id + '_' + reply.id,
                          "feedback-id": _ctx.feedbackItem.id,
                          reply: reply
                        }, null, 8, ["feedback-id", "reply"]))
                      }), 128)),
                      _createVNode(_component_FeedbackNewReply, {
                        "feedback-item-id": _ctx.feedbackItem.id
                      }, null, 8, ["feedback-item-id"])
                    ]))
                  : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          class: _normalizeClass({'btn feedback-small': true, 'blue-styled-button': _ctx.isFeedbackRepliesOn(_ctx.feedbackItem.id), 'blue-styled-button-outline': !_ctx.isFeedbackRepliesOn(_ctx.feedbackItem.id)}),
          title: "View or add a comment",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleReplies(_ctx.feedbackItem.id)))
        }, [
          _cache[1] || (_cache[1] = _createTextVNode(" Comments ")),
          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "far fa-comment" }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.feedbackItem["num_comments"]), 1)
        ], 2),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedbackItem.tags, (tag, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: _ctx.feedbackItem.id+'_tag'+index,
              class: "tag-span"
            }, _toDisplayString(tag), 1))
          }), 128))
        ]),
        _createVNode(_component_FeedbackVote, {
          "feedback-id": _ctx.feedbackItem.id
        }, null, 8, ["feedback-id"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.feedbackItem.author + ' - ' + _ctx.getDate(_ctx.feedbackItem.datetime)), 1)
      ])
    ])
  ]))
}