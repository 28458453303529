/** Status **/
import { FeedbackStatus, FeedbackStatusDetail } from "./types"

export function getStatusClassIcon (status: FeedbackStatus): string {
    switch (status) {
    case FeedbackStatus.New:
        return "far fa-bookmark"
    case FeedbackStatus.InProgress:
        return "fas fa-hourglass-half"
    case FeedbackStatus.Solved:
        return "far fa-check-circle"
    case FeedbackStatus.Discarded:
        return "fas fa-trash"
    case FeedbackStatus.DoLater:
        return "far fa-clock"
    default:
        return ""
    }
}

function getStatusName (status: FeedbackStatus): string {
    switch (status) {
    case FeedbackStatus.New:
        return "New"
    case FeedbackStatus.InProgress:
        return "In progress"
    case FeedbackStatus.Solved:
        return "Solved"
    case FeedbackStatus.Discarded:
        return "Discarded"
    case FeedbackStatus.DoLater:
        return "Do later"
    default:
        return ""
    }
}

// Export a constant containing the tags details (value code / label) once to be used throughout the application
export const statusDetails = [] as FeedbackStatusDetail[]
for (const statusCode of Object.values(FeedbackStatus)) {
    if (!isNaN(Number(statusCode))) {
        statusDetails.push({ value: statusCode as FeedbackStatus, label: getStatusName(statusCode as FeedbackStatus) })
    }
}

// Voting isn't posible is local storage isn't available
export const isVotingPossible = (typeof (Storage) !== "undefined")

// For saving the feedback items id in web storage and in store
export function getFeedbackVoteId (feedbackItemId: number, feedbackItemReplyId: number): string {
    return (feedbackItemReplyId > 0) ? (feedbackItemId + "-" + feedbackItemReplyId) : ("" + feedbackItemId)
}

// Get all feedbacks voted up stored in the web storage (so we can use reactivity in Vue)
const webStorageFeedbackVoteKey = "feedbackVotes"
export function getFeedbackVoteIdsFromWebStorage (): string[] {
    const votesData = localStorage.getItem(webStorageFeedbackVoteKey)
    if (votesData) {
        return votesData.split(";")
    }
    else {
        return []
    }
}

// Save a feedback vote in the web storage
export function saveFeedbackVoteIdInWebStorage (feedbackVoteId: string): void {
    const votesData = localStorage.getItem(webStorageFeedbackVoteKey)
    // create a new key/value pair with the item id or appenend the item id if data already exists
    // the values are semi colon separated ids, ids are either a feedback id or <feedback id>-<feedback reply id>
    const newPairValue = (votesData !== null) ? (votesData + ";" + feedbackVoteId) : feedbackVoteId
    localStorage.setItem("feedbackVotes", newPairValue)
}
