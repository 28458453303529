export function beforeUnloadEvent (event: BeforeUnloadEvent): void {
    // If a change is notified when leaving a page, alert the user. Browsers handle this and show their own message.
    // Note that the same behaviour during route navigation is handled differently because it's not notified by "beforeunload"
    event.returnValue = true
}

export function getLocale (): string {
    if (navigator.languages) {
        return navigator.languages[0]
    }
    return navigator.language
}

export function getLineBreaksParsedFreeText (innerHTMLContent: string): string {
    // Function to parse the linke breaks from free text elements as \n for saving in DB
    // replace div elments by a "\n"
    // replace br elements by empy string: extra lines are already added by <div>
    return innerHTMLContent
        .replaceAll("</div>", "").replaceAll("</DIV>", "")
        .replaceAll("<div>", "\n").replaceAll("<DIV>", "\n")
        .replaceAll("<br>", "").replaceAll("<BR>", "")
}

export function pasteTextOnly (pasteEvent: ClipboardEvent): void {
    // If there is *only* some text objects to paste, we leave the paste to be handled as normal
    // however, if there some elements that are not textual, we *programmatically* paste whatever text content we can retrieve

    const pastedItems = pasteEvent.clipboardData?.items
    let hasNonTextualData = false
    if (pastedItems) {
        for (const transferData of pastedItems) {
            if (transferData.type !== "text/plain") {
                hasNonTextualData = true
            }
        }
    }

    if (hasNonTextualData && pasteEvent.clipboardData?.getData("text")) {
        pasteEvent.preventDefault()
        pasteEvent.stopImmediatePropagation()
        document.execCommand("insertText", false, pasteEvent.clipboardData.getData("text"))
    }
}
