<template>
  <div class="vflex vflex-centered error-container">
    <i
      class="fas fa-exclamation-triangle"
      :style="style"
    />
    <span>{{ label }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "Error",

    props: {
        label: { type: String, default: "" },
        size: { type: String, default: "3em" },
    },

    computed: {
        style () : Record<string, string> {
            return { "font-size": this.size, color: "#689e52" }
        },
    },
})
</script>

<style>
.error-container {
    row-gap: 2px;
    padding: 0.25em;
}

</style>
