import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card strype-card new-reply" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = ["id", "title"]
const _hoisted_4 = {
  key: 0,
  class: "new-reply-controls-table"
}
const _hoisted_5 = ["id", "maxlength"]
const _hoisted_6 = { rowspan: "2" }
const _hoisted_7 = { class: "hflex hflex-right action-container" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Progress = _resolveComponent("Progress")!
  const _component_Error = _resolveComponent("Error")!
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        id: _ctx.replyMsgDivId,
        contenteditable: "true",
        class: _normalizeClass(["card-text styled-input-text-clear new-reply-text", {'input-error': (_ctx.isMsgTooBig)}]),
        title: _ctx.getMsgErrorMsg(),
        onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onKeyUp && _ctx.onKeyUp(...args))),
        onPaste: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPaste($event)))
      }, null, 42, _hoisted_3),
      (_ctx.showControls)
        ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
            _createElementVNode("tbody", null, [
              _cache[7] || (_cache[7] = _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("hr")
                ])
              ], -1)),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("input", {
                    id: _ctx.replyAuthorInputId,
                    placeholder: "Your username (optional)",
                    class: "styled-input-text styled-input-text-fullW",
                    maxlength: _ctx.authorMaxLength
                  }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("td", _hoisted_6, [
                  (_ctx.isDBSendingFeedbackCommment)
                    ? (_openBlock(), _createBlock(_component_Progress, {
                        key: 0,
                        label: "Sending...",
                        class: "newFeedbackComment-dbprocess-indicator"
                      }))
                    : (_ctx.hasDBError)
                      ? (_openBlock(), _createBlock(_component_Error, {
                          key: 1,
                          label: _ctx.dbErrorMsg,
                          class: "newFeedbackComment-dbprocess-indicator"
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createVNode(_component_vue_recaptcha, {
                    ref: "replyCaptcha",
                    class: "app-captcha",
                    sitekey: _ctx.captchaKey,
                    onVerify: _cache[2] || (_cache[2] = ($event: any) => (_ctx.captchaVerified()))
                  }, null, 8, ["sitekey"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("button", {
                      class: "btn red-styled-button",
                      disabled: _ctx.isDBSendingFeedbackCommment,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clear()))
                    }, _cache[5] || (_cache[5] = [
                      _createTextVNode(" Cancel "),
                      _createElementVNode("i", { class: "fas fa-times" }, null, -1)
                    ]), 8, _hoisted_8),
                    _createElementVNode("button", {
                      class: "btn blue-styled-button",
                      disabled: !_ctx.isUserHuman || _ctx.isDBSendingFeedbackCommment,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.createNewFeedbackReply()))
                    }, _cache[6] || (_cache[6] = [
                      _createTextVNode(" Submit "),
                      _createElementVNode("i", { class: "fas fa-paper-plane" }, null, -1)
                    ]), 8, _hoisted_9)
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}