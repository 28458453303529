<template>
  <div class="card strype-card">
    <div class="card-body">
      <p class="card-text feedback-text">
        {{ reply.content }}
      </p>
      <div class="feedback-summary-right">
        <FeedbackVote
          :feedback-id="feedbackId"
          :feedback-reply-id="reply.id"
        />
        <span>{{ reply.author + ' - ' + getDate(reply.datetime) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { FeedbackReply } from "@/helpers/types"
import store from "@/store/store"
import { getFormattedLocalDateFromUTCStr } from "@/helpers/dateRendering"
import FeedbackVote from "@/components/FeedbackVote.vue"

export default defineComponent({
    name: "FeedbackReply",
    store,

    components: {
        FeedbackVote,
    },

    props: {
        feedbackId: { type: Number, default: -1, required: true },
        reply: {
            type: Object as PropType<FeedbackReply>,
            required: true,
        },
    },

    methods: {
        getDate (utcDate: string): string {
            return getFormattedLocalDateFromUTCStr(utcDate)
        },
    },
})
</script>

<style>
</style>
