<template>
  <div class="card strype-card">
    <div class="card-body feedback-small">
      <div class="feedback-header feedback-medium">
        <span>{{ feedbackItem.title }}</span>
        <i :class="getStatusClass(feedbackItem.status)" />
      </div>
      <p class="card-text feedback-text">
        {{ feedbackItem.content }}
      </p>
      <div
        v-if="isFeedbackRepliesOn(feedbackItem.id)"
        class="vflex replies-container"
      >
        <Progress
          v-if="isDBLoadingFeedbackCommments"
          label="Loading..."
          class="feedbackComments-dbprocess-indicator"
        />
        <Error
          v-else-if="hasDBError"
          :label="dbErrorMsg"
          class="feedbackComments-dbprocess-indicator"
        />
        <div v-else-if="canShowReplies">
          <FeedbackReply
            v-for="reply in feedbackItem.comments"
            :key="feedbackItem.id + '_' + reply.id"
            :feedback-id="feedbackItem.id"
            :reply="reply"
          />
          <FeedbackNewReply
            :feedback-item-id="feedbackItem.id"
          />
        </div>
      </div>
      <div class="hflex hflex-centered">
        <button
          :class="{'btn feedback-small': true, 'blue-styled-button': isFeedbackRepliesOn(feedbackItem.id), 'blue-styled-button-outline': !isFeedbackRepliesOn(feedbackItem.id)}"
          title="View or add a comment"
          @click="toggleReplies(feedbackItem.id)"
        >
          Comments
          <i class="far fa-comment" />&nbsp;{{ feedbackItem["num_comments"] }}
        </button>
        <div class="hflex flex-grow">
          <span
            v-for="(tag, index) in feedbackItem.tags"
            :key="feedbackItem.id+'_tag'+index"
            class="tag-span"
          >
            {{ tag }}
          </span>
        </div>
        <FeedbackVote :feedback-id="feedbackItem.id" />
        <span>{{ feedbackItem.author + ' - ' + getDate(feedbackItem.datetime) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { DBConsts, DBError, DBOperation, DBOperationType, FeedbackItem, FeedbackReply as FeedbackReplyType, FeedbackStatus } from "@/helpers/types"
import store from "@/store/store"
import FeedbackReply from "@/components/FeedbackReply.vue"
import FeedbackNewReply from "@/components/FeedbackNewReply.vue"
import FeedbackVote from "@/components/FeedbackVote.vue"
import Progress from "@/components/Progress.vue"
import Error from "@/components/Error.vue"
import { getFormattedLocalDateFromUTCStr } from "@/helpers/dateRendering"
import { getStatusClassIcon } from "@/helpers/feedback"
import axios from "axios"

export default defineComponent({
    name: "FeedbackSummary",
    store,

    components: {
        FeedbackReply,
        FeedbackNewReply,
        FeedbackVote,
        Progress,
        Error,
    },

    props: {
        feedbackItem: {
            type: Object as PropType<FeedbackItem>,
            required: true,
        },
    },

    data: function () {
        return {
            fullFeedbackIDs: [] as string[],
        }
    },

    computed: {
        canShowReplies () :boolean {
            // We need this flag also, otherwise, changes on the operation can update another FeedbackSummary component and show the new reply input
            // even if comments could not be retrieved
            return this.feedbackItem.num_comments === this.feedbackItem.comments.length
        },

        isDBLoadingFeedbackCommments (): boolean {
            const dbOperation = store.getters.getDBOperation() as DBOperation
            const isOperationForThisFeedback = (dbOperation.payload && dbOperation.payload[0] === this.feedbackItem.id) as boolean
            return dbOperation.type === DBOperationType.FeedbackComments && isOperationForThisFeedback
        },

        dbErrorInfos (): DBError {
            return store.getters.getDBError()
        },

        hasDBError (): boolean {
            const isErrorForThatFeedback = (this.dbErrorInfos.payload && this.dbErrorInfos.payload[0] === this.feedbackItem.id) as boolean
            return this.dbErrorInfos.flag && this.dbErrorInfos.operation === DBOperationType.FeedbackComments && isErrorForThatFeedback
        },

        dbErrorMsg (): string {
            return this.dbErrorInfos.error?.toString() ?? ""
        },
    },

    methods: {
        getStatusClass (status: FeedbackStatus): string {
            const preamble = "feedback-summary-right "
            return preamble + getStatusClassIcon(status)
        },

        getDate (dbDate: string): string {
            return getFormattedLocalDateFromUTCStr(dbDate)
        },

        isFeedbackRepliesOn (feedbackId: string): boolean {
            return this.fullFeedbackIDs.includes(feedbackId)
        },

        toggleReplies (feedbackId: string) {
            if (!this.fullFeedbackIDs.includes(feedbackId)) {
                this.fullFeedbackIDs.push(feedbackId)

                // when replies are toggled for the first time, we need to load them
                if (this.feedbackItem.comments.length !== this.feedbackItem.num_comments) {
                    // first we set the flags that we are retrieving data on the DB
                    store.commit("setDBOperation", { type: DBOperationType.FeedbackComments, payload: [this.feedbackItem.id] })
                    store.commit("setDBError", { flag: false })
                    // the perform the query to retrieve the data
                    axios.get((store.getters.getDBConsts() as DBConsts).serverhost + "comments/" + this.feedbackItem.id)
                        .then((response) => {
                            store.commit("setFeedbackComments", { feedbackItemId: this.feedbackItem.id, feedbackItemComments: response.data.comments as FeedbackReplyType[] })
                        })
                        .catch((error) => store.commit("setDBError", { flag: true, error: error, operation: DBOperationType.FeedbackComments, payload: [this.feedbackItem.id] }))
                        .finally(() => {
                            store.commit("setDBOperation", { type: DBOperationType.None })
                        })
                }
            }
            else {
                this.fullFeedbackIDs.splice(this.fullFeedbackIDs.indexOf(feedbackId), 1)
            }
        },
    },
})
</script>

<style>
.feedback-header {
    font-weight: 600;
}

.feedback-small {
   font-size: 12px !important;
}

.feedback-medium {
   font-size: 14px !important;
}

.feedback-summary-right {
    float: right;
}

.feedback-text {
    white-space: pre-wrap;
}

.replies-container {
    row-gap: 10px;
}

.tag-span {
    color: rgb(27,69,111);
    margin: 0px 5px;
}

.feedbackComments-dbprocess-indicator {
    margin-top: 5px;
    margin-bottom: 20px;
}

.strype-card {
    background-color: rgba(255, 255, 255, 0.6) !important;
    max-width: 59vw;
}
</style>
