import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card strype-card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-text feedback-text" }
const _hoisted_4 = { class: "feedback-summary-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedbackVote = _resolveComponent("FeedbackVote")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.reply.content), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FeedbackVote, {
          "feedback-id": _ctx.feedbackId,
          "feedback-reply-id": _ctx.reply.id
        }, null, 8, ["feedback-id", "feedback-reply-id"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.reply.author + ' - ' + _ctx.getDate(_ctx.reply.datetime)), 1)
      ])
    ])
  ]))
}