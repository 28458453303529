import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "feedbackSummaryViewSection",
  class: "hflex"
}
const _hoisted_2 = {
  id: "feedbackLeftContainer",
  class: "vflex flex-grow"
}
const _hoisted_3 = { class: "vflex feedback-container" }
const _hoisted_4 = {
  key: 2,
  id: "noFeedbackParagraph"
}
const _hoisted_5 = {
  id: "loadMoreFeedbacksContainerDiv",
  class: "vflex"
}
const _hoisted_6 = {
  key: 0,
  id: "searchFilterContainer",
  class: "vflex"
}
const _hoisted_7 = {
  id: "searchContainer",
  class: "hflex"
}
const _hoisted_8 = { class: "hflex styled-input-text flex-grow" }
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Progress = _resolveComponent("Progress")!
  const _component_Error = _resolveComponent("Error")!
  const _component_FeedbackSummary = _resolveComponent("FeedbackSummary")!
  const _component_FeedbackFilter = _resolveComponent("FeedbackFilter")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isDBLoadingFeedbacks && !_ctx.hasDBFeedbacksError)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.newFeedbackRoute
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("button", { class: "btn yellow-styled_button" }, [
                _createTextVNode(" New "),
                _createElementVNode("i", {
                  class: "fas fa-plus-circle",
                  title: "New feedback"
                })
              ], -1)
            ])),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isDBLoadingFeedbacks || _ctx.isDBLoadingFilteredFeedbacks)
          ? (_openBlock(), _createBlock(_component_Progress, {
              key: 0,
              size: "5em",
              label: "Loading...",
              class: "feedbacks-dbprocess-indicator"
            }))
          : (_ctx.hasDBFeedbacksError || _ctx.hasDBFilteredFeedbacksError)
            ? (_openBlock(), _createBlock(_component_Error, {
                key: 1,
                size: "5em",
                label: _ctx.dbErrorMsg,
                class: "feedbacks-dbprocess-indicator"
              }, null, 8, ["label"]))
            : (_ctx.feedbackItems.length === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.noFeedbackMsg), 1))
              : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedbackItems, (feedbackItem) => {
          return (_openBlock(), _createElementBlock("div", {
            key: feedbackItem.id
          }, [
            _createVNode(_component_FeedbackSummary, { "feedback-item": feedbackItem }, null, 8, ["feedback-item"])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.showLoadMoreLink)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                id: "loadMoreFeedbacksSpanLink",
                href: "#",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMoreFeedbacks && _ctx.loadMoreFeedbacks(...args)))
              }, "Load more feedbacks ..."))
            : _createCommentVNode("", true),
          (_ctx.isDBLoadingMoreFeedbacks)
            ? (_openBlock(), _createBlock(_component_Progress, {
                key: 1,
                label: "Loading..."
              }))
            : (_ctx.hasDBMoreFeedbacksError)
              ? (_openBlock(), _createBlock(_component_Error, {
                  key: 2,
                  label: _ctx.dbErrorMsg
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
        ])
      ])
    ]),
    (!_ctx.isDBLoadingFeedbacks && !_ctx.hasDBFeedbacksError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("input", {
                id: "searchInput",
                type: "text",
                placeholder: "Search",
                class: "naked-input-text flex-grow",
                onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkIsSearchEnabled && _ctx.checkIsSearchEnabled(...args))),
                onKeypress: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.searchFeedback && _ctx.searchFeedback(...args)), ["enter"]))
              }, null, 32),
              _createElementVNode("span", {
                class: _normalizeClass({'search-clear-btn': _ctx.isSearchEnabled, 'search-clear-btn-disabled': !_ctx.isSearchEnabled}),
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearSearchFeedback && _ctx.clearSearchFeedback(...args)))
              }, "✖", 2)
            ]),
            _createElementVNode("button", {
              class: "search-btn",
              disabled: !_ctx.isSearchEnabled,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.searchFeedback && _ctx.searchFeedback(...args)))
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("i", {
                class: "fas fa-search",
                title: "Search feedback tickets"
              }, null, -1)
            ]), 8, _hoisted_9),
            _createElementVNode("button", {
              class: _normalizeClass({'search-btn': true, 'search-active-btn': _ctx.hasFilter}),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleFilterFeedback()))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("i", {
                class: "fas fa-filter",
                title: "Filter feedback tickets"
              }, null, -1)
            ]), 2)
          ]),
          _withDirectives(_createVNode(_component_FeedbackFilter, {
            ref: "feedbackFilter",
            onFilterApplied: _cache[6] || (_cache[6] = ($event: any) => (_ctx.filterActionPerformed(true))),
            onFilterReset: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterActionPerformed(false)))
          }, null, 512), [
            [_vShow, _ctx.showFeedbackFilter]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}