<template>
  <div
    class="hflex tags-section"
  >
    <button
      v-for="(tag, index) in tags"
      :key="'tag'+index"
      class="styled-button-clear tag-button"
      :disabled="disabled"
    >
      <span ref="tagLabelSpan">{{ tag }}</span>
      <a
        :class="{'disabled': disabled}"
        @click="deleteTag(index)"
      >&times;</a>
    </button>
    <span
      id="tagSpanInput"
      :class="{'flex-grow feedback-changeable styled-input-text': true, 'tag-span-hidden': !acceptTags()}"
      :contenteditable="!disabled"
      :data-placeholder="inputPlaceholder"
      @paste="pasteTags($event)"
      @blur="addTag()"
      @keydown="checkTagKeyDown($event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue"

const tagsMax = 5
export default defineComponent({
    name: "FeedbackTags",

    props: {
        inputPlaceholder: { type: String, required: true },
        disabled: { type: Boolean, default: false },
    },

    emits: ["tag-added", "tags-deleted"],

    data () {
        return {
            tags: [] as string[], // The array of tags for this new feedback - updated when tags are added or removed. The max number of tags is defined by the tagsMax constant.
        }
    },

    mounted () {
        // Set the tags span input height (in style) so that it remains the same regardless being editable or not
        // Note: we use a span rather than an input for the tags for presentation: it behaves nicer in the filter than an input
        const inputSpan = document.getElementById("tagSpanInput") as HTMLSpanElement
        inputSpan.style.height = inputSpan.offsetHeight + "px"
    },

    methods: {
        acceptTags (): boolean {
            return this.tags.length < tagsMax
        },

        checkTagKeyDown (keyEvent: KeyboardEvent) {
            // When a key is hit on the tags section the following applies:
            // - space, enter, comma and semi colon triggers the tag addition and are not rendered
            // - other keys are rendered normally
            const validators = [" ", ",", ";", "enter"]
            if (validators.includes(keyEvent.key.toLowerCase())) {
                this.addTag()
                keyEvent.preventDefault()
            }
        },

        addTag () {
            // We retrieve the content of the tags span to add it in the tags array of this component's data - tags buttons will be updated accordingly
            // if the tag starts with #, we remove this hashtag prefix.
            const tagInputSpan = document.getElementById("tagSpanInput")
            const tagText = (tagInputSpan as HTMLSpanElement).textContent
            if (tagText) {
                this.doAddTagText(tagText)
            }
        },

        doAddTagText (tagText: string) {
            tagText = tagText.replace(/^#/, "")
            if (tagText.length > 0 && this.tags.length < tagsMax) {
                this.tags.push(tagText)
                // we can then remove the text from the span
                const tagInputSpan = document.getElementById("tagSpanInput")
                if (tagInputSpan?.textContent) {
                    tagInputSpan.textContent = ""
                }
                // Emit an event for whoever needs to consume a tag addition
                this.$emit("tag-added")
            }
        },

        pasteTags (pasteEvent: ClipboardEvent) {
            const pastedText = pasteEvent.clipboardData?.getData("text")
            if (pastedText) {
                // When some text is pasted in the tags, we do the following:
                // - extract the tags indivually (cf @keydown) from whatever is in field already and the content of the paste
                // - add tags that can be added (so it doesn't exceed the max allowed)
                // - prevent the copy (cf after the if condition here)
                const tagSplitterRegex = /[\s,;]/
                const tagInputSpan = document.getElementById("tagSpanInput")
                if (tagInputSpan) {
                    // Something may be selected, the cursor may not be at the end of the text already input,
                    // so we need to check that and reconstruct what would be the input if a paste was really achieved
                    const currentSelection = document.getSelection()
                    if (currentSelection) {
                        const inputPreSel = tagInputSpan.textContent?.substr(0, Math.min(currentSelection.anchorOffset, currentSelection.focusOffset))
                        const inputPostSel = tagInputSpan.textContent?.substr(Math.max(currentSelection.anchorOffset, currentSelection.focusOffset))
                        const inputWithPaste = inputPreSel + pastedText + inputPostSel
                        inputWithPaste.split(tagSplitterRegex).forEach((tagBit) => this.doAddTagText(tagBit.trim()))
                    }
                }
            }

            // if no text is to be pasted, we just don't paste anything anyway
            pasteEvent.preventDefault()
        },

        deleteTag (index: number) {
            this.tags.splice(index, 1)
            // Emit an event for whoever needs to consume all tags deletion (next click as we need to make sure the UI has been updated properly)
            if (this.tags.length === 0) {
                nextTick(() => this.$emit("tags-deleted"))
            }
        },

        getTags (): string[] {
            return this.tags
        },

        clearTags () {
            this.tags.splice(0, this.tags.length)
        },
    },
})
</script>

<style>
.tags-section {
    color: rgb(27,69,111);
    background-color: transparent !important;
    column-gap: 5px;
}

.tag-button {
    color:rgb(27,69,111);
}

#tagSpanInput {
    background-color: rgba(255, 255, 255, 0.6);
    width:auto;
}

#tagSpanInput:empty:before {
    content: attr(data-placeholder);
}

.tag-span-hidden {
    visibility: hidden;
}
a.disabled {
    pointer-events: none;
    cursor: default;
}
</style>
