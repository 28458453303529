import { createApp } from "vue"
import App from "./App.vue"
import store from "./store/store"
import router from "./router/router"

createApp(App).use(store).use(router).mount("#app")
// We guess the viewport orientation via windows.innerW/H and use listeners for updating changes
// note that the screen and screen Orientation API could be used but is not supported by some browsers
store.commit("setIsScreenPortrait", (window.innerHeight > window.innerWidth))
window.onresize = () => store.commit("setIsScreenPortrait", (window.innerHeight > window.innerWidth))
