import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "strypeHistoryViewSection",
  class: "vflex"
}
const _hoisted_2 = { class: "strype-history-entry-sub-container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { type: "circle" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { type: "circle" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { type: "circle" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Release history", -1)),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history, (release, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'strypeRelease_'+index,
          class: "strype-history-entry-container"
        }, [
          _createElementVNode("b", null, _toDisplayString(release.date), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", {
              innerHTML: release.preambleDetails
            }, null, 8, _hoisted_3),
            (release.generalDetails)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, " General Details "))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(release.generalDetails, (generalDetail, index2) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: 'strypeRelease_'+index+'general_'+index2,
                  innerHTML: generalDetail
                }, null, 8, _hoisted_6))
              }), 128))
            ]),
            (release.standardEditorDetails)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Strype standard editor "))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(release.standardEditorDetails, (standardEditorDetail, index2) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: 'strypeRelease_'+index+'standardeditor_'+index2,
                  innerHTML: standardEditorDetail
                }, null, 8, _hoisted_9))
              }), 128))
            ]),
            (release.microbitEditorDetails)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Strype for micro:bit "))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(release.microbitEditorDetails, (microbitEditorDetail, index2) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: 'strypeRelease_'+index+'general_'+index2,
                  innerHTML: microbitEditorDetail
                }, null, 8, _hoisted_12))
              }), 128))
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}