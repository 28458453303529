
// NOTE: as a time zone offset changes depending on what date we look at, don't keep a constant...

export function getFormattedLocalDateFromUTCStr (UTCDateStr: string): string {
    // Get a readable localised date from an ISO date
    const localDate = new Date(UTCDateStr) // This will give local date
    return Intl.DateTimeFormat(undefined, { dateStyle: "short", timeStyle: "short" }).format(new Date(localDate))
}

export function getFormattedUTCStrDateFromLocal (localDate: Date, useLocaleDayStartTime?: boolean, useLocaleDayEndTime?: boolean): string {
    // Get an "ISO" UTC date from a locate date (format: year-month-day hours:minutes:seconds eg 2022-02-26 16:27:36)
    if (useLocaleDayStartTime || useLocaleDayEndTime) {
        localDate.setHours((useLocaleDayStartTime) ? 0 : 23)
        localDate.setMinutes((useLocaleDayStartTime) ? 0 : 59)
        localDate.setSeconds((useLocaleDayStartTime) ? 0 : 59)
        localDate.setMilliseconds((useLocaleDayStartTime) ? 0 : 999)
    }
    return localDate.getUTCFullYear() + "-" +
        ((localDate.getUTCMonth() < 9) ? "0" + (localDate.getUTCMonth() + 1) : (localDate.getUTCMonth() + 1)) + "-" +
        ((localDate.getUTCDate() < 10) ? "0" + localDate.getUTCDate() : localDate.getUTCDate()) + " " +
        ((localDate.getUTCHours() < 10) ? "0" + localDate.getUTCHours() : localDate.getUTCHours()) + ":" +
        ((localDate.getUTCMinutes() < 10) ? "0" + localDate.getUTCMinutes() : localDate.getUTCMinutes()) + ":" +
        ((localDate.getUTCSeconds() < 10) ? "0" + localDate.getUTCSeconds() : localDate.getUTCSeconds())
}
