<template>
  <div class="card strype-card new-reply">
    <div class="card-body">
      <div
        :id="replyMsgDivId"
        contenteditable="true"
        class="card-text styled-input-text-clear new-reply-text"
        :class="{'input-error': (isMsgTooBig)}"
        :title="getMsgErrorMsg()"
        @keyup="onKeyUp"
        @paste="onPaste($event)"
      />
      <table
        v-if="showControls"
        class="new-reply-controls-table"
      >
        <tbody>
          <tr>
            <td>
              <hr>
            </td>
          </tr>
          <tr>
            <td>
              <input
                :id="replyAuthorInputId"
                placeholder="Your username (optional)"
                class="styled-input-text styled-input-text-fullW"
                :maxlength="authorMaxLength"
              >
            </td>
            <td rowspan="2">
              <Progress
                v-if="isDBSendingFeedbackCommment"
                label="Sending..."
                class="newFeedbackComment-dbprocess-indicator"
              />
              <Error
                v-else-if="hasDBError"
                :label="dbErrorMsg"
                class="newFeedbackComment-dbprocess-indicator"
              />
            </td>
          </tr>
          <tr>
            <td>
              <vue-recaptcha
                ref="replyCaptcha"
                class="app-captcha"
                :sitekey="captchaKey"
                @verify="captchaVerified()"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div
                class="hflex hflex-right action-container"
              >
                <button
                  class="btn red-styled-button"
                  :disabled="isDBSendingFeedbackCommment"
                  @click="clear()"
                >
                  Cancel
                  <i class="fas fa-times" />
                </button>
                <button
                  class="btn blue-styled-button"
                  :disabled="!isUserHuman || isDBSendingFeedbackCommment"
                  @click="createNewFeedbackReply()"
                >
                  Submit
                  <i class="fas fa-paper-plane" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import store from "@/store/store"
import { VueRecaptcha } from "vue-recaptcha"
import { getLineBreaksParsedFreeText, pasteTextOnly } from "@/helpers/misc"
import { DBConsts, DBError, DBOperation, DBOperationType } from "@/helpers/types"
import Progress from "@/components/Progress.vue"
import Error from "@/components/Error.vue"

export default defineComponent({
    name: "FeedbackNewReply",
    store,

    components: {
        VueRecaptcha,
        Progress,
        Error,
    },

    props: {
        feedbackItemId: { type: Number, required: true },
    },

    data: function () {
        return {
            showControls: false,
            isUserHuman: false,
            isMsgTooBig: false,
        }
    },

    computed: {
        msgMaxLength (): number {
            return (store.getters.getDBConsts() as DBConsts).longTextMax
        },

        authorMaxLength (): number {
            return (store.getters.getDBConsts() as DBConsts).extraShortTextMax
        },

        replyMsgDivId (): string {
            return "feedbackReplyMsgDiv" + this.feedbackItemId
        },

        replyAuthorInputId (): string {
            return "feedbackReplyAuthorInput" + this.feedbackItemId
        },

        captchaKey (): string {
            return store.getters.getCaptchaKey()
        },

        isDBSendingFeedbackCommment (): boolean {
            const currentOperation = store.getters.getDBOperation() as DBOperation
            const isOperationForThisFeedback = (currentOperation.payload && currentOperation.payload[0] === this.feedbackItemId) as boolean
            return currentOperation.type === DBOperationType.NewFeedbackComment && isOperationForThisFeedback
        },

        dbErrorInfos (): DBError {
            return store.getters.getDBError()
        },

        hasDBError (): boolean {
            const isErrorForThatFeedbackReply = (this.dbErrorInfos.payload && this.dbErrorInfos.payload[0] === this.feedbackItemId) as boolean
            return this.dbErrorInfos.flag && this.dbErrorInfos.operation === DBOperationType.NewFeedbackComment && isErrorForThatFeedbackReply
        },

        dbErrorMsg (): string {
            return this.dbErrorInfos.error?.toString() ?? ""
        },
    },

    watch: {
        // whenever we notify sending has finished, if there are no error we can move on
        isDBSendingFeedbackCommment (isSending, wasSending) {
            if (wasSending && !isSending && !this.hasDBError) {
                this.clear()
            }
        },
    },

    methods: {
        onKeyUp () {
            // When the reply div is non empty, we show the controls
            this.showControls = ((((document.getElementById(this.replyMsgDivId) as HTMLDivElement).innerText?.trim().length) ?? 0) > 0)
        },

        captchaVerified () {
            this.$data.isUserHuman = true
        },

        createNewFeedbackReply () {
            const parsedMsg = getLineBreaksParsedFreeText((document.getElementById(this.replyMsgDivId) as HTMLDivElement).innerHTML).trim()
            this.isMsgTooBig = (parsedMsg.length > this.msgMaxLength)
            if (!this.isMsgTooBig) {
                store.commit("setNewFeedbackReply", {
                    feedbackItemId: this.feedbackItemId,
                    content: parsedMsg,
                    author: (document.getElementById(this.replyAuthorInputId) as HTMLInputElement).value.trim(),
                })
                // we only clear the new reply if we succeeded getting a new id for that reply in the state
                // cf watchers
            }
        },

        clear () {
            (document.getElementById(this.replyMsgDivId) as HTMLDivElement).textContent = ""
            this.showControls = false
            this.isUserHuman = false
            this.isMsgTooBig = false;
            (this.$refs.replyCaptcha as VueRecaptcha).reset()
            // As clear() can be called when there is an error (cancel) we also reset the error & sending flags here
            store.commit("setDBError", { flag: false })
            store.commit("setDBOperation", { type: DBOperationType.None })
        },

        getMsgErrorMsg (): string | null {
            return (this.isMsgTooBig) ? "Content cannot exceed " + this.msgMaxLength + " characters" : null
        },

        onPaste (pasteEvent: ClipboardEvent) {
            pasteTextOnly(pasteEvent)
        },
    },
})
</script>

<style>
.new-reply {
    margin-bottom: 10px;
}

.new-reply-text {
    white-space: pre-wrap;
}

.new-reply-text[contenteditable=true]:empty:before{
  content: "Your reply...";
  color:grey;
  font-style:italic;
}

.new-reply-controls-table {
    width: 100%;
}

.new-reply-controls-table td{
    width: 50%;
}

.newFeedbackComment-dbprocess-indicator {
    width: 50%;
}
</style>
