import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vflex vflex-centered error-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: "fas fa-exclamation-triangle",
      style: _normalizeStyle(_ctx.style)
    }, null, 4),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ]))
}