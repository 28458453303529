import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card strype-card vflex new-feedback-container" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "feedback-medium" }
const _hoisted_4 = { id: "feedbackInfoTable" }
const _hoisted_5 = ["id", "title", "maxlength", "disabled"]
const _hoisted_6 = ["id", "contenteditable", "title"]
const _hoisted_7 = ["id", "maxlength", "disabled"]
const _hoisted_8 = {
  id: "newfeedbackBottomControlsDiv",
  class: "hflex"
}
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedbackTags = _resolveComponent("FeedbackTags")!
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!
  const _component_Progress = _resolveComponent("Progress")!
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _cache[8] || (_cache[8] = _createElementVNode("th", null, "Title", -1)),
              _createElementVNode("td", null, [
                _createElementVNode("input", {
                  id: _ctx.titleInputId,
                  placeholder: "Add a title here",
                  class: _normalizeClass({'feedback-changeable styled-input-text': true, 'input-error': _ctx.isTitleEmpty}),
                  title: (_ctx.isTitleEmpty)?'Title cannot be empty':null,
                  maxlength: _ctx.titleMaxLength,
                  disabled: _ctx.isDBSendingFeedback,
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPendingChanges(_ctx.titleInputId)))
                }, null, 42, _hoisted_5)
              ])
            ]),
            _createElementVNode("tr", null, [
              _cache[9] || (_cache[9] = _createElementVNode("th", null, "Description", -1)),
              _createElementVNode("td", null, [
                _createElementVNode("div", {
                  id: _ctx.descriptionDivId,
                  contenteditable: !_ctx.isDBSendingFeedback,
                  class: _normalizeClass({'card-text new-feedback-text feedback-changeable styled-input-text': true, 'input-error': (_ctx.isDescriptionEmpty || _ctx.isDescriptionTooBig)}),
                  title: _ctx.getDescErrorMsg(),
                  onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPendingChanges(_ctx.descriptionDivId))),
                  onPaste: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPaste($event)))
                }, null, 42, _hoisted_6)
              ])
            ]),
            _createElementVNode("tr", null, [
              _cache[10] || (_cache[10] = _createElementVNode("th", null, " Tags ", -1)),
              _createElementVNode("td", null, [
                _createVNode(_component_FeedbackTags, {
                  ref: "tagsComponent",
                  "input-placeholder": 'Add a tag here (optional)',
                  class: "card-text feedback-changeable",
                  disabled: _ctx.isDBSendingFeedback,
                  onTagAdded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPendingChanges())),
                  onTagsDeleted: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setPendingChanges()))
                }, null, 8, ["disabled"])
              ])
            ]),
            _createElementVNode("tr", null, [
              _cache[11] || (_cache[11] = _createElementVNode("th", null, "Author", -1)),
              _createElementVNode("td", null, [
                _createElementVNode("input", {
                  id: _ctx.authorInputId,
                  placeholder: "Your username (optional)",
                  class: "feedback-changeable styled-input-text",
                  maxlength: _ctx.authorMaxLength,
                  disabled: _ctx.isDBSendingFeedback,
                  onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setPendingChanges(_ctx.titleInputId)))
                }, null, 40, _hoisted_7)
              ])
            ]),
            _createElementVNode("tr", null, [
              _cache[12] || (_cache[12] = _createElementVNode("th", null, null, -1)),
              _createElementVNode("td", null, [
                _createVNode(_component_vue_recaptcha, {
                  class: "app-captcha",
                  sitekey: _ctx.captchaKey,
                  onVerify: _cache[6] || (_cache[6] = ($event: any) => (_ctx.captchaVerified()))
                }, null, 8, ["sitekey"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            (_ctx.isDBSendingFeedback)
              ? (_openBlock(), _createBlock(_component_Progress, {
                  key: 0,
                  label: "Sending...",
                  class: "newFeedback-dbprocess-indicator"
                }))
              : (_ctx.hasDBError)
                ? (_openBlock(), _createBlock(_component_Error, {
                    key: 1,
                    label: _ctx.dbErrorMsg,
                    class: "newFeedback-dbprocess-indicator"
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("button", {
            class: "btn blue-styled-button",
            disabled: !_ctx.hasPendingChanges || !_ctx.isUserHuman || _ctx.isDBSendingFeedback,
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.createNewFeedback()))
          }, _cache[13] || (_cache[13] = [
            _createTextVNode(" Submit "),
            _createElementVNode("i", { class: "fas fa-paper-plane" }, null, -1)
          ]), 8, _hoisted_9)
        ])
      ])
    ])
  ]))
}