import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "hflex tags-section" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["contenteditable", "data-placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
      return (_openBlock(), _createElementBlock("button", {
        key: 'tag'+index,
        class: "styled-button-clear tag-button",
        disabled: _ctx.disabled
      }, [
        _createElementVNode("span", {
          ref_for: true,
          ref: "tagLabelSpan"
        }, _toDisplayString(tag), 513),
        _createElementVNode("a", {
          class: _normalizeClass({'disabled': _ctx.disabled}),
          onClick: ($event: any) => (_ctx.deleteTag(index))
        }, "×", 10, _hoisted_3)
      ], 8, _hoisted_2))
    }), 128)),
    _createElementVNode("span", {
      id: "tagSpanInput",
      class: _normalizeClass({'flex-grow feedback-changeable styled-input-text': true, 'tag-span-hidden': !_ctx.acceptTags()}),
      contenteditable: !_ctx.disabled,
      "data-placeholder": _ctx.inputPlaceholder,
      onPaste: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pasteTags($event))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addTag())),
      onKeydown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkTagKeyDown($event)))
    }, null, 42, _hoisted_4)
  ]))
}