<template>
  <section class="vflex">
    <h2>
      Forthcoming events
    </h2>
    <ul>
      <li>
        <a
          href="https://www.kcl.ac.uk/events/teaching-python-programming-with-strype"
          target="_blank"
        >Teaching Python Programming with Strype</a>, workshop at King's College London (25/11/2024)
      </li>
      <li>
        <span>Fachschaftsleitertagung Informatik in Oberfranken, online talk, Germany (18/11/2024)</span>
      </li>
      <li>
        <a
          href="https://www.raspberrypi.org/blog/the-clubs-conference-is-coming-back/"
          target="_blank"
        >Raspberry Pi Clubs Conference</a>, talk in Cambridge (30/11/2024 to 01/12/2024)
      </li>
      <li>
        <a
          href="https://www.kcl.ac.uk/events/teaching-python-programming-with-strype"
          target="_blank"
        >Teaching Python Programming with Strype</a>, workshop at King's College London (10/12/2024)
      </li>
    </ul>
    <br>
    <p>
      We have presented Strype at the following events:
    </p>
    <ul>
      <li>
        <a
          href="https://www.wipsce.org/2024/"
          target="_blank"
        >WiPSCE 2024</a> and the <a
          href="https://www.edu.sot.tum.de/ddi/fuer-lehrkraefte/iltb-2024/"
          target="_blank"
        >Bavarian CS teachers day</a> in Munich, Germany
      </li>
      <li>
        <a
          href="https://sigcse2024.sigcse.org/"
          target="_blank"
        >SIGCSE 24</a> in Portland, Oregon, USA
      </li>
      <li>
        <a
          href="https://www.raspberrypi.org/blog/clubs-conference-2023-talks/"
          target="_blank"
        >Raspberry Pi Clubs Conference 2023</a> in Cambridge, UK
      </li>
      <li>
        <a
          href="https://sigcse2023.sigcse.org/"
          target="_blank"
        >SIGCSE 2023</a>, Toronto, Canada
      </li>
      <li>
        <a
          href="https://iticse.acm.org/2022/"
          target="_blank"
        >ITiCSE 2022</a>, Dublin, Ireland
      </li>
      <li>
        <a
          href="https://sigcse2022.sigcse.org/index.html"
          target="_blank"
        >SIGCSE 2022</a>, Providence, Rhode Island, USA
      </li>
      <li>
        <a
          href="https://www.wipsce.org/2021/"
          target="_blank"
        >WiPSCE 2021</a>, online conference
      </li>
    </ul>
  </section>
  <br>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "EventsView",
})
</script>

<style>
</style>
